.form {
    text-align: left;

    &-input-error {
        border: 0.0625rem var(--cu-red-500) solid !important;
    }

    &-error {
        font-size: x-small;
        color: var(--cu-red-500);
        font-style: italic;
    }

    .error-box {
        // min-height: 1rem;
        text-align: left;


        p {
            line-height: .8rem;
        }
    }
}
.cur8-form {
    input {
        width: 100%;
        background-color: var(--cu-black-700);
        border: 1px solid var(--cu-black-40);
        border-radius: var(--border-radius-lg);
        color: var(--cu-white-900);
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
    }
}